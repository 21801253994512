$(() => {
  $("#unsubscribe-form").on('click','input[type=checkbox]',function(){
    var notchecked = $("#unsubscribe-form input[type=checkbox]:checked").length == 0;
    $('#unsubscribe-form button[type=submit]').prop('disabled',notchecked);
  }).trigger('click');
  $("#unsubscribe-form").on('click','input[type=radio]',function(){
    var checked = $("#unsubscribe-form input[type=radio]:checked").val();
    if (checked == 'contact_assignor') {
      $('#unsubscribe-button').html("Contact Assignor(s)");
      $('#unsubscribe-form #message-container').slideDown();
    } else {
      $('#unsubscribe-button').html("Unsubscribe Me");
      $('#unsubscribe-form #message-container').slideUp();
    }
  }).trigger('click');
});
