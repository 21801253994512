//http://railscasts.com/episodes/197-nested-model-form-part-2
var remove_fields, add_fields;

$(() => {
	remove_fields = function(link) {
	  $(link).siblings("input[type=hidden]").val("1");
	  $(link).closest(".row").hide();
	};
	add_fields = function(link, association, content) {
	  var new_id = new Date().getTime();
	  var regexp = new RegExp("new_" + association, "g");
	  $(link).parent().before(content.replace(regexp, new_id));
	};
})

var abilityWidth = 0;

$(() => {

  // add an icon to every a.newrecord
  $("a.newrecord").prepend("<i class='fa-solid fa-plus'></i> ");

  //case insensitive search
  //example: $("div:icontains('John')").css("text-decoration", "underline");
  $.expr[':'].icontains = function(obj, index, meta, stack){
    return (obj.textContent || obj.innerText || jQuery(obj).text() || '')
    .toLowerCase()
    .indexOf(meta[3].toLowerCase()) >= 0;
  };

	$.fn.preventDoubleSubmit = function() {
	  $(this).on('submit',function() {
	    if (this.beenSubmitted)
	      return false;
	    else
	      this.beenSubmitted = true;
	  });
	};
  // prevent double submits on every form
  $("form").preventDoubleSubmit;

  // hide the announcements and set the cookie when the hide link is clicked
  $("#system-announcement").on('click',
     function(){
       var today = new Date() ;
       $.cookie('announcement_hide_time', today.toUTCString(),{ expires: 30 });
       $(this).hide();
     }
   );

  // click the menu items on the new help screens
  $('ul.nav li.visible div,ul.nav li.invisible div').on('click',function(){
   $(this).siblings("ul").slideToggle("slow");
   $(this).parent().siblings().children("ul").slideUp("slow");
  });

  $('dd#mobile_phone a').on('click',function(){
    $(this).siblings("img").show();
    $(this).hide();
  });

});
