import Dinero from 'dinero.js'
import ledger from './numbers.js'

$(() => {

  if ($('.pagination').length && $('#ledger-statements').length){
    $(window).scroll(function(){
      var url = $('.pagination a[rel=next]:first').attr('href')
      var topOfTable = $('#ledger-statements').position().top;
      var heightOfTable = $('#ledger-statements').height();
      var loadPoint = topOfTable + heightOfTable - $(window).height() - 50;
      if (url && $(window).scrollTop() > loadPoint){
        console.log({
          topOfTable: topOfTable,
          heightOfTable: heightOfTable,
          loadPoint: loadPoint,
          scrollTop: $(window).scrollTop(),
        });
        $('.pagination').html("<span class='in-process fa-solid fa-rotate fa-spin fa-color-primary'></span> <strong>Loading Additional Statements...</strong>")
        console.log('get', url);
        $.getScript(url)
      }
    })
    $(window).scroll();
  }

  Dinero.defaultCurrency = $('meta[name=currency]').attr('content');
  // $('#ledger-statement-search select[multiple]').addClass('form-control').select2();
  // $('#ledger-reports select[multiple]').addClass('form-control').select2();

  $('.ledger-statements input[type=checkbox][name=check_all]').on('click',function(){
    var checked = $(this).prop('checked')
    $(this).closest('table').find('tr td input[type=checkbox]').prop('checked',checked);
    $(this).trigger('checkbox-changed');
  });
  $('.ledger-statements tr td input[type=checkbox]').on('click',function(){
    $(this).trigger('checkbox-changed');
  })
  $('.ledger-statements').on('checkbox-changed',function(){
    if ($(this).find('tr td input[type=checkbox]:checked').length > 0){
      $('.pay-selected').attr('disabled',null).removeClass('disabled');
    } else {
      $('.pay-selected').attr('disabled', 'disabled').addClass('disabled');
    }
    var total =
    $('.statement-checkbox:checked')
      .map(function(){
        return Dinero({
          amount: parseInt(
            $(this).closest('tr').data('amount-due')
          )
        });
      })
      .toArray()
      .reduce(function(a,b){
          return a.add(b)
        },
        Dinero({amount: 0})
      )
      .toFormat();
    $("#selected-count").html( $('.statement-checkbox:checked').length );
    $("#selected-amount").html( total );
    console.log('dinero sez total is ' + total);

  }).trigger('checkbox-changed');

  var recalc = function(){
    var values = $(this).find('.expense-line-item').map(function(){
      if ($(this).prop('disabled')){
        return 0.0;
      } else {
        return ledger.numberValue($(this).val());
      }
    }).toArray();
    var thetotal = values.reduce(function(total, currentValue){
      return total + currentValue;
    },0.0);
    console.log(thetotal, values);
    $(this).find('.ap-line-item').val(thetotal);
    $(this).find('.ap-line-item-placeholder').html(ledger.numberWithCommas(thetotal));
  };
  $('#edit-payor-statement-table')
  .on('change', '.toggle-delete input[type=checkbox]',function(){
    var checkbox = $(this);
    console.log('clicked!', checkbox[0].checked,checkbox[0])
    var row = $(this).closest('tr');
    if (checkbox[0].checked){
      row.addClass('to-delete');
    } else {
      row.removeClass('to-delete');
    }
    row.find('select').prop('disabled', checkbox[0].checked);
    row.find('input[type=text],input[type=number]').prop('disabled', checkbox[0].checked);
    return true 
  })
  .on('keyup',recalc)
  .on('change',recalc);
  $('#edit-payor-statement-table').closest('form').on('submit',recalc);
  $('#edit-payor-statement-table').trigger('keyup');
});
