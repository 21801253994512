(function() {
  var domainParts = document.domain.split(/\./);
  var domain = [domainParts[domainParts.length - 2], domainParts[domainParts.length-1]].join(".");
  $.cookie.defaults = {
    domain: domain,
    path: '/',
    json: true
  };

  $.cookie.json = true;

})()