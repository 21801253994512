$(() => {
	function showHideCriteria(){
		switch ($('#search_class_name').val()){
			case "game":
				$('#search_games_criteria').show();
				$('#search_user_criteria').hide();
				$('#search_user_criteria input,#search_user_criteria select').val(null);
			break;
			case "user":
				$('#search_games_criteria').hide();
				$('#search_games_criteria input,#search_games_criteria select').val(null);
				$('#search_user_criteria').show();
			break;
			case "team":
				$('#search_games_criteria input, #search_games_criteria select').val(null);
				$('#search_user_criteria input, #search_user_criteria select').val(null);
				$('#search_games_criteria').hide();
				$('#search_user_criteria').hide();
			break;
			default:
		}		
	}
	showHideCriteria();
	$('#search_class_name').change(function(){
			showHideCriteria();			
	});	
})