$(() => {
  $("a#is-assignor").click(function(){
    $.cookie("is-assignor-signup","yep");
    $("form#new_signup").slideDown();
    $("#who-are-you").slideUp();
  });

  if($.cookie("is-assignor-signup")){
    $("form#new_signup").show();
    $("#who-are-you").hide();
  }
});
