$(() => {
  $("form").on('change', '.click-to-confirm',function(){
    $(this).closest('form').find('.disabled').addClass('confirmable');
    $(this).closest('form').find('[disabled="disabled"]').addClass('confirmable');
    if ($(this)[0].checked){
      $(this).closest('form').find('.confirmable').prop('disabled', false).removeClass('disabled');
    } else {
      $(this).closest('form').find('.confirmable').prop('disabled', true).addClass('disabled');
    }
  });
  $('form .click-to-confirm').trigger('change');
});
