$(() => {
  
  function colorSelectionFormatter(color, container){
    if (color.id == "") return color.text;
    return "<img style='background-color: #"+color.id+"' class='league-color' src='/assets/colors/"+color.id+".png' /> "+ color.text;
  }

  $("#league_color").select2({
      placeholder: "Choose...",
      formatResult: colorSelectionFormatter,
      formatSelection: colorSelectionFormatter,
      escapeMarkup: function(m) { return m; },      
  });
  
});