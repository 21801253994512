$(() => {
    $('#find-timesheets-panel-body #search_contact_ids').select2()
    $('#find-timesheets-panel-body #search_statuses').select2()
    $('#find-timesheets-panel-body #search_zone_ids').select2()
    $('#timesheet-table input[type=checkbox][name=check_all]').on('click', function () {
        var checked = $(this).prop('checked')
        $(this).closest('table').find('tr td input[type=checkbox]').prop('checked', checked);
        $(this).trigger('checkbox-changed');
    });
    $('#timesheet-table input[type=checkbox]').on('click', function () {
        $(this).trigger('checkbox-changed');
    });


    //
    $('#timesheet-table').on('checkbox-changed', function () {
        console.log('changed!')
        if ($(this).find('tr td input[type=checkbox]:checked').length > 0) {
            $('.approve-selected').attr('disabled', null).removeClass('disabled');
        } else {
            $('.approve-selected').attr('disabled', 'disabled').addClass('disabled');
        }
    }).trigger('checkbox-changed');


    // auto-load timesheets on scroll
    if ($('.pagination').length && $('#timesheet-table').length) {
        $(window).scroll(function () {
            var url = $('.pagination a[rel=next]:first').attr('href')
            var topOfTable = $('#timesheet-table').position().top;
            var heightOfTable = $('#timesheet-table').height();
            var loadPoint = topOfTable + heightOfTable - $(window).height() - 50;
            if (url && $(window).scrollTop() > loadPoint) {
                console.log({
                    topOfTable: topOfTable,
                    heightOfTable: heightOfTable,
                    loadPoint: loadPoint,
                    scrollTop: $(window).scrollTop(),
                });
                $('.pagination').html("<span class='in-process fa-solid fa-rotate fa-spin fa-color-primary'></span> <strong>Loading Additional Timesheets...</strong>")
                console.log('get', url);
                $.getScript(url)
            }
        })
        $(window).scroll();
    }
})
