$(() => {
  if ($('#all-registration-fees').length > 0){
    $('#currency-select').on('change',function(){
      var currency = $(this).val();
      $("#all-registration-fees .fee-row").hide();
      $("#all-registration-fees .fee-row[data-currency='"+currency+"']").show();
    })
    .trigger('change');
  }
  $(".new_registration_type, .edit_registration_type")
    .on("change","#registration_type_period",function(){
        $("#date-range-block,#duration-block").hide();
        if ($(this).val() == 'range'){
          $("#date-range-block").show();
        }
        if ($(this).val() == 'duration'){
          $("#duration-block").show();
        }
    });
  $("#registration_type_period").trigger("change")

  $(".new_registration_type, .edit_registration_type")
    .on("change","#registration_type_payment",function(){
        $("#registration-amount-block,#processing-fee-block").hide();
        $("#self-entry-block").show();
        $("#self-entry-fake-block").hide();
        if ($(this).val() == 'manual'){
          $("#registration-amount-block").show();
        }
        if ($(this).val() == 'stripe'){
          $("#self-entry-block").hide();
          $("#self-entry-fake-block").show();
          $("#registration-amount-block,#processing-fee-block").show();
        }
    });
  $("#registration_type_payment").trigger("change")

  $("body").on("change",".registration_field_form .field-type-select",function(){
    console.log("change!");
    var selectOptionsBlock = $(this).closest('form').find(".select-options-block");
    if($(this).val() == 'select'){
      selectOptionsBlock.show();
    } else {
      selectOptionsBlock.hide();
    }
  })
  $(".field-type-select").trigger("change");

  $("body").on("click",'#registration-custom-fields-list .move-up',function(){
    var url = $(this).data("move-url");
    var table = $("#registration-custom-fields-list");
    $.post(url, {
      _method: 'PUT',
      registration_field: {
        _move_record_up: '1'
      }
    }).done(function(){
      $.get(table.data("reload-url"));
    });
  });

  $("body").on("click",'#registration-custom-fields-list .move-down',function(){
    var url = $(this).data("move-url");
    var table = $("#registration-custom-fields-list");
    $.post(url, {
      _method: 'PUT',
      registration_field: {
        _move_record_down: '1'
      }
    }).done(function(){
      $.get(table.data("reload-url"));
    });
  });

});
