

$(() => {
  var ls
  try {
      ls = window.sessionStorage;
  } catch (err) {
      ls = null;
  }

  $('body').on('calendar-rendered','#availability-tbl', function(){
    if (ls){
      var previous = ls.getItem("freedate-toggle-edit");
      if (previous){
        $(`#availability-tbl input[name="fd-toggle-edit"][value="${previous}"]`).parent("label").click();
      }
      var showTimes = ls.getItem("freedate-view-times");
      if (showTimes == 'yes'){
        $('#availability-tbl').addClass('show-times');
        $('#fd-toggle-view-times .btn').addClass('active');
        $('#fd-toggle-view-times input[type=checkbox]').prop('checked', true);
      }
    }
  });
  $('body').on('change','#fd-view-times',function(e){
    e.preventDefault();
    if (ls){
      var value = $(this).prop('checked') ? 'yes' : 'no';
      ls.setItem('freedate-view-times',value);
    }
    if ($(this).prop('checked')){
      $('#availability-tbl').addClass('show-times');
    } else {
      $('#availability-tbl').removeClass('show-times');
    }
  });
  $('body').on('calendar-rendered','#availability-tbl', function(){
    if (ls){
      var previous = ls.getItem("freedate-view-times");
      if (previous){
        $(`#availability-tbl input[name="fd-toggle-edit"][value="${previous}"]`).parent("label").click();
      }
    }
  });
  $('#availability-tbl').trigger('calendar-rendered');
  $('body').on('change', 'form#free-date-month-selector select', function(e){
    e.preventDefault();
    var form = $(this).closest('form')
    var data = form.serialize()
    var url = form[0].action
    $.get(`${url}?${data}`)
  });
  $('body').on('submit','#availability-accordion form', function(){
    $("#availability-accordion form:visible")
    $(this).closest("form").find('[type=submit]').prop('disabled', 'disabled')
  });
  $('body').on('change', '#availability-accordion .time-available-picker',function(){
    if ($(this).val() == 'allday'){
      $(this).closest('.panel-body').find('.times-available').slideUp();
      $(this).closest('.panel-body').find('.times-available input:visible').val('');
    }
    if ($(this).val() == 'times'){
      $(this).closest('.panel-body').find('.times-available').slideDown();
    }
  });
  $('#availability-accordion .time-available-picker').trigger('change');
  $('body').on('click', '#availability-tbl .day-toggle',function(e){
    var button = $(this);
    $(this).prop('disabled', true);
    $('.day-toggle').tooltip('hide');
    e.preventDefault();
    var date = $(this).data('date');
    if ($('#fd-toggle-date')[0].checked) {
      var url = $(this).data('toggleUrl');
      $.post(url, {date: date})
      .always(function(){
        button.prop('disabled', false);
      });

    } else {
      var url = $(this).data('timesUrl');
      $.get(url)
      .always(function(){
        button.prop('disabled', false);
      });
    }
  }).on("change", '#availability-tbl [name="fd-toggle-edit"]', function(){
    console.log('click!');
    if (ls){
      ls.setItem("freedate-toggle-edit", $(this).val());
    }
  });
  $('body').on("show.bs.modal", '#availability-for-date-modal',function(){
    $('.day-toggle').tooltip('hide');
    console.log('showing modal');
    var modal = $(this);
    modal.on("click", '.all-day-checkbox',function(){
      console.log('click!', $(this));
      if ($(this)[0].checked){
        $(this).closest('tr').find("input[type=text]:visible").val('').prop('disabled', true);
      } else {
        $(this).closest('tr').find("input[type=text]:visible").prop('disabled', false);
      }
    })
    .on("click", '.remove-time', function(){
      console.log("remove!")
      var table = modal.find('table:first');
      $(this).closest("tr").remove();
      if (table.find('td:visible').length > 0){
        table.find("th").removeClass('hidden');
        $('#alert-not-available').addClass('hidden');
      } else {
        table.find("th").addClass('hidden');
        $('#alert-not-available').removeClass('hidden');
      }
    })
    .on("click", '#add-time', function(){
      var table = modal.find('table:first');
      var newRow = modal.find("tr.placeholder:first").clone();
      var container;
      table.find("th").removeClass('hidden');
      $('#alert-not-available').addClass('hidden');
      var nowValue = new Date().getTime();
      newRow.removeClass('placeholder').removeClass('hidden')
      .find("input, select, textarea").each(function() {
        $(this).attr("name", function() {
          return $(this)
            .attr("name")
            .replace("placeholder", nowValue);
        });
      })
      .find("label").each(function() {
        $(this).attr("for", function() {
          return $(this)
            .attr("for")
            .replace("placeholder", nowValue);
        });
      })
      newRow.appendTo(table);
    })
    // modal.on()
  });

});
