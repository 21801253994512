const ledger = {
  numberValue: function(v){
    var number = parseFloat(v);
    if (isNaN(number)) {
      return 0.0;
    } else {
      return Math.round(number * 100.0) / 100.0;
    }
  },
  numberWithCommas: function (num,isInteger) {
    var string = num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    if (isInteger){
      return string.replace(/\.[0-9]{2}$/,'');
    } else {
      return string;
    }
  },
  cents: function(input){
    var number = parseFloat(input);
    var value;
    if (isNaN(number)) {
      return 0;
    } else {
      return parseInt(Math.round(input * 100.0));
    }
  }
};
export default ledger;
