$(() => {
  $("form.edit_site input[type='radio'][name='site[game_request_method]']").change(function(){
    // if the value is a self-assign value, show the #referee-self-assign div, otherwise hide it.
    if ($(this).val().match(/self_assign/)) {
      $("#referee-self-assign").slideDown();
    } else {
      $("#referee-self-assign").slideUp();    
    }
  });
  if ( $("form.edit_site").length ){
    if ( $("form.edit_site input[type='radio'][name='site[game_request_method]']:checked").val().match(/self_assign/)){
      $("#referee-self-assign").show();
    }else{
      $("#referee-self-assign").hide();    
    }
  }
});