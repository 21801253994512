$(() => {

  $("#existingStripeCard").on("change",function(){
    if ($(this).val() == ""){
      $("#new-credit-card-entry").slideDown();
    } else {
      $("#new-credit-card-entry").slideUp();
    }
  }).trigger("change");

  // $("#payment-form").on("click",".stripe-submit",function(){
  //   $(this).data("enabledMessage",$(this).html());
  //   $(this).html($(this).data('disableMessage'));
  //   $(this).prop('disabled',true);
  // }).on("stripe-submit-error",function(){
  //   var button = $(".stripe-button");
  //   button.html(button.data('enabledMessage'));
  //   button.prop('disabled',false);
  // })
  // ;

  var cardElement = document.getElementById("card-element");
  if (cardElement){
    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '12px',
        color: "#858585",
        fontFamily: '"Open Sans", HelveticaNeue, Helvetica, Arial'
      }
    };
    // Create an instance of the card Element.
    var postalCode = document.getElementById("card-postal-code");
    var countryCode = document.getElementById("card-country");
    var stateCode = document.getElementById("card-state");
    var card = elements.create('card', {style: style, hidePostalCode: !!postalCode});

    if (typeof google != 'undefined'){
      var geocoder = new google.maps.Geocoder();
    }
    if (postalCode){
      postalCode.addEventListener('change', function(event) {
        var component = {
          postalCode: postalCode.value,
          country: countryCode.value
        };

        geocoder.geocode( { address: postalCode.value,componentRestrictions: component}, function(results, status) {
          if(status == "OK"){
            card.update({value: {postalCode: event.target.value}});
            for (var i = 0; i < results[0].address_components.length; i++) {
              var component = results[0].address_components[i];
              if (component.types.indexOf("administrative_area_level_1") >= 0){
                stateCode.value = component.short_name;
                console.log("state is", stateCode.value);
              }
            }
          }
          console.log([results,status]);
        });
      });
    }

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');
    card.addEventListener('change', function(event) {
      var renewalPlan = document.getElementById("renewal_plan_id")
      if (renewalPlan) {
        var option = renewalPlan.options[renewalPlan.selectedIndex];
        if (option && option.dataset.total == '0.0'){
          return true
        }
      }
      console.log(['change-card',event]);
      var displayError = document.getElementById('card-errors');
      var useExistingCardSelect = document.getElementById("existingStripeCard");
      if (useExistingCardSelect && useExistingCardSelect.value != ""){
        console.log("skipping change event, ", useExistingCardSelect.value);
        return true;
      }
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', function(event) {
      var useExistingCardSelect = document.getElementById("existingStripeCard");
      if (useExistingCardSelect && useExistingCardSelect.value != ""){
        return true;
      }
      var renewalPlan = document.getElementById("renewal_plan_id")
      if (renewalPlan) {
        var option = renewalPlan.options[renewalPlan.selectedIndex];
        if (option && option.dataset.total == '0.0'){
          return true
        }
      }
      var countryCode = document.getElementById("card-country");
      var stateCode = document.getElementById("card-state");

      event.preventDefault();
      var submits = form.querySelectorAll('[type=submit]');
      for (var i = 0; i < submits.length; i++) {
        submits[i].disabled = true;
      }

      // $("#payment-form [type=submit]").prop("disabled",true);

      var tokenData = {};
      if (stateCode){
        tokenData.address_state = stateCode.value;
        tokenData.address_country = countryCode.value;
      }

      stripe.createToken(card,tokenData).then(function(result) {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          var submits = form.querySelectorAll('[type=submit]');
          for (var i = 0; i < submits.length; i++) {
            submits[i].disabled = false;
          }
        } else {
          // Send the token to your server.
          stripeTokenHandler(result.token);
        }
      });
    });

    function stripeTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById('payment-form');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);

      // Submit the form
      form.submit();
    }

  }

});
