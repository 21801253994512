$(() => {

	/* on the AgeGroup screen, fill down the game fees when the fill down link is clicked */
	$('.fee-fill-down').click(
		function(e){
			var gameFee = $(this).siblings('.age-group-fee').val();
			var payScale = $(this).siblings('.age-group-fee').attr("data-pay-scale");
			$('.age-group-fee[data-pay-scale="'+ payScale+'"]:visible' ).val(gameFee);
      $(this).siblings('.age-group-fee').focus();
			e.preventDefault();
		}
	);

  // adjust the width of the name element, so that it behaves like an HTML table

	$("#age-fee-selector select").on("change",function(){
		var pattern = $("#age-fee-selector [name=pattern_id]").val();
		var position = $("#age-fee-selector [name=position_id]").val();
		var criteria = "";
		if (pattern != "") {
			criteria += "[data-pattern-id!="+pattern+"]";
		}
		if (position != "") {
			criteria += "[data-position-id!="+position+"]";
		}
		$("#age-fees-table tbody tr")
			.removeClass("hidden")
			.removeClass("first")
		  .addClass("shown");
		if (criteria != "") {
			$("#age-fees-table tbody tr" + criteria)
				.addClass("hidden")
				.removeClass("shown");
		}
		$("#age-fees-table tbody tr.shown:first").addClass("first");
	}).trigger("change");
});
