$(() => {
  $('.payor-due-disabled').on('refresh-due',function(){
    var url = $(this).data('payor-url');
    var element = $(this);
    var currentValue = $(this).find('.unpaid').html();
    $.get(url)
    .done(function(data){
      element.find('.balance').html(data.balance);
      element.find('.unpaid-count').html(data.unpaid_count);
      if (data.in_process && element.find('.in-process').length == 0) {
        element.prepend("<span class='in-process fa-solid fa-rotate fa-spin fa-color-primary'></span>");
      } else if(!data.in_process && element.find('.in-process').length > 0) {
        element.find('.in-process').remove();
        element.prepend("<span class='completed fa-solid fa-circle-check fa-color-success'></span>");
      } else if ( element.find('.completed').length > 0){
        element.find('.completed').fadeOut(400,function(){
          $(this).remove();
        });
      }
      setTimeout(function(){ element.trigger('refresh-due') }, 5000);
    });
  })
  .trigger('refresh-due');

});
