$(() => {
  // JS escaping a string
  function escapeStr(str) {
    if(str){
      return str.replace(/([#;&,.+*~\':"!^$[\]()=>|\/@])/g,'\\$1');
    } else {
      return str;
    }
  }

  $('form.edit_pay_scale input#search, form.new_pay_scale input#search').keyup(function(){
    $("tr.pay-scale-fee-item:visible").hide();
    term = escapeStr($(this).val());
    $("tr.pay-scale-fee-item:icontains('"+term+"')").show();
    $('a.fill-down').hide();
    $('tr.pay-scale-fee-item:visible:first a.fill-down').show();
  });
  $('form.edit_pay_scale a.fill-down, form.new_pay_scale a.fill-down').click(function(e){
    amount = $(this).siblings('input').val();
    $('tr.pay-scale-fee-item:visible input.pay-scale-fee').val(amount);
    e.preventDefault();
  });

  $('form.edit_pay_scale tr.pay-scale-fee-item:visible:first a.fill-down, '+
    'form.new_pay_scale tr.pay-scale-fee-item:visible:first a.fill-down').show();

});
