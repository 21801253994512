function makeDroppable(element, callback) {

  var input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('multiple', true);
  input.setAttribute('accept', 'video/*');
  input.style.display = 'none';

  input.addEventListener('change', triggerCallback);
  element.appendChild(input);

  element.addEventListener('dragover', function(e) {
    e.preventDefault();
    e.stopPropagation();
    element.classList.add('dragover');
  });

  element.addEventListener('dragleave', function(e) {
    e.preventDefault();
    e.stopPropagation();
    element.classList.remove('dragover');
  });

  element.addEventListener('drop', function(e) {
    e.preventDefault();
    e.stopPropagation();
    element.classList.remove('dragover');
    triggerCallback(e);
  });

  element.addEventListener('click', function() {
    input.value = null;
    input.click();
  });

  function triggerCallback(e) {
    var files;
    if(e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if(e.target) {
      files = e.target.files;
    }
    callback.call(null, files);
  }
}


$(() => {

  var element = document.querySelector('#video-upload');
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
  function uploadFile(file){
    var form = new FormData();
    var uploading = parseInt($('#video-upload-next').data('active-uploads'));
    if (!file.name.match(/\.(3g[2p]|asf|avi|drc|f4[abpv]|flv|m2v|m4p|m4v|mkv|mng|mov|mp2|mp4|mp(e|g|eg|v)|mxf|nsv|og[gv]|qt|rm(vb)?|roq|svi|vob|webm|wmv|yuv)$/i)) {
      alert("This doesn't appear to be a video file (" +file.name+ ")");
      return;
    }
    $('#video-upload-next').data('active-uploads',uploading + 1);
    var key = s3Policy.key.replace('${filename}','') + getRandomInt(1000000,10000000) + '/'+file.name;
    form.append("key", key);
    form.append("acl", s3Policy.acl);
    form.append("policy", s3Policy.policy);
    form.append("signature", s3Policy.signature);
    form.append("AWSAccessKeyId", s3Policy.access_key);
    form.append("x-amz-meta-site-id", s3Policy.site_id);
    form.append("utf8", "✓");
    form.append("success_action_status", "201");
    form.append("file", file);
    host = "https://"+s3Policy.bucket+".s3.amazonaws.com";
    var loadingText = $('#video-progress-item')
      .text()
      .replace('{filename}',file.name)
      .replace('{s3Key}',key);

    $("#video-upload-progress").append(loadingText);
    $('#video-upload-next a' ).slideUp();
    console.log(form);
    $.ajax({
      url: host,
      method: 'post',
      data: form,
      chunking: true,
      processData: false,
      contentType: false,
      success: function(response) {
        $('#video-upload-next').show();
        var uploading = parseInt($('#video-upload-next').data('active-uploads'));
        $('#video-upload-next').data('active-uploads',uploading - 1);
        if ((uploading - 1) > 0) {
          $('#video-upload-next a' ).slideUp();
        } else {
          $('#video-upload-next a').slideDown();
        }
        $("#video-upload-progress p[data-s3-key='"+key+"']").slideUp();
        $.post('/video/uploads.json',{
          video: {
            original_s3_key: key
          }
        })
      }
    });

  }
  function callback(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      uploadFile(files[i]);
      // var file = files[i];
    }
  }
  if ($('#video-upload').length > 0) {
    makeDroppable($('#video-upload')[0], callback);
  }

  $('.video-thumbnail.processing').each(function(element){
    $.get($(this).data('processing-url'));
  });

  $(".edit_video").on("change","#video_show_survey",function(){
    if ($("#video_show_survey:checked")[0]) {
      $(".ymtk-survey").slideDown();
    } else {
      $(".ymtk-survey").slideUp();
    }
  })

  $(".edit_video #video_show_survey").trigger("change");

  // JS for share
  $('#new_inbox_message select[multiple]').select2({ allowClear: true });
  $('#expand_recipient_ids').on('click', function(e) {
    e.preventDefault();
    const recipients = $('#inbox_message_recipient_ids').val() || [];
    $.post('/messages/expand.json', { recipients: recipients })
        .done(function(data){
            $('#inbox_message_recipient_ids').val(data).trigger('change');
        })
  });
//   $("form#new_inbox_message")
//
//   .on('click','a.select-all',function(e){
//       $('form#new_inbox_message #inbox_message_recipient_ids option').prop('selected', true);
//       $('form#new_inbox_message #inbox_message_recipient_ids').trigger('change');
//       e.preventDefault();
//   })
//
//   .on('click','a.select-officials',function(e){
//       $('form#new_inbox_message #inbox_message_recipient_ids option.recipient.referee').prop('selected', true);
//       $('form#new_inbox_message #inbox_message_recipient_ids option.recipient.official').prop('selected', true);
//       $('form#new_inbox_message #inbox_message_recipient_ids').trigger('change');
//       e.preventDefault();
//   })
//
//   .on('click','a.select-observers',function(e){
//       $('form#new_inbox_message #inbox_message_recipient_ids option.recipient.observer').prop('selected', true);
//       $('form#new_inbox_message #inbox_message_recipient_ids').trigger('change');
//       e.preventDefault();
//   })
//
//   .on('click','a.select-managers',function(e){
//       $('form#new_inbox_message #inbox_message_recipient_ids option.recipient.manager').prop('selected', true);
//       $('form#new_inbox_message #inbox_message_recipient_ids').trigger('change');
//       e.preventDefault();
//   })
//
//
//   .on('click','a.select-none',function(e){
//     $('form#new_inbox_message #inbox_message_recipient_ids option').prop('selected', false);
//     $('form#new_inbox_message #inbox_message_recipient_ids').trigger('change');
//     e.preventDefault();
//   });
//   if ($("form#new_inbox_message").length > 0){
//     $.get($("form#new_inbox_message").data('search-url'));
//   }
//
//
//
});
