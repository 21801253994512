$(() => {
  $('.dwolla-biz-classifications').select2()
  $('form.dwolla_customer_form')
    .on('change', '.ledger_dwolla_customer_type', function () {
      if ($('.ledger_dwolla_customer_type:checked').val() == 'business') {
        $(this)
          .closest('form')
          .addClass('customer-type-business')
          .removeClass('customer-type-personal')
      } else {
        $(this)
          .closest('form')
          .removeClass('customer-type-business')
          .addClass('customer-type-personal')
        $('#ledger_dwolla_customer_businessType').val('')
      }
    })
    .on('change', '#ledger_dwolla_customer_businessType', function () {
      if ($(this).val() == 'soleProprietorship') {
        $(this).closest('form').addClass('sole-proprietor')
      } else {
        $(this).closest('form').removeClass('sole-proprietor')
      }
    })
  $('form.dwolla_customer_form .ledger_dwolla_customer_type').trigger('change')
  $('form.dwolla_customer_form #ledger_dwolla_customer_businessType').trigger(
    'change'
  )
})
