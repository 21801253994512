$(() => {


  $('#import-file-status').on('refresh-table',function(){
    var statusUrl = $('#import-file-status').data('status-url')
    $.get(statusUrl)
    .fail(function(data){
      setTimeout(function(){
        $('#import-file-status').trigger('refresh-table');
      }, 5000);
    })
    .done(function(data){
      console.log(data)
      var previousStatus = $('#import-file-status').data('status');
      $('#import-file-status').data('status', data.status);
      $('.import-status').html(data.friendly_status);
      $('.added-count').html(data.added_count);
      $('.row-count').html(data.row_count);
      $('.error-count').html(data.error_count);
      $('#success-bar').css("width",data.percent_success + "%")
      $('#error-bar').css("width",data.percent_error + "%")
      if (previousStatus == 'started' && data.status != 'started'){
        window.location.href = window.location.href;
      }
      if (data.in_progress){
        setTimeout(function(){
          $('#import-file-status').trigger('refresh-table');
        }, 1500);
      }
    })
  })

  if ($('#import-file-status')){
    $('#import-file-status').trigger('refresh-table');
  }

  // // options elements cannot be hidden via css
  // // use javascript to remove these elements from everyone else's view
  // // pcaha hack

  // (function() {

  //   function removeRatingsFromSelects(){
  //     $("form.edit_import_file")
  //       .find(
  //             "option[value='game_enterprise_site_tag'], " +
  //             "option[value='referee_enterprise_site_tag'] "
  //             )
  //       .remove();
  //   }

  //   function removeEndTimeFromSelects(){
  //     $("form.edit_import_file")
  //       .find("option[value='game_user_end_time_formatted']")
  //       .remove();
  //   }



  //   if ($("form.edit_import_file").length > 0) {
  //     var enterpriseGroup = $("body").data("enterprise-group");
  //     var siteID = $("body").data("site-id");
  //     if (siteID === 432 || siteID == 9101) {
  //       // hide ratings but keep end times
  //       removeRatingsFromSelects();
  //     } else if ( !enterpriseGroup ) {
  //      removeRatingsFromSelects();
  //      removeEndTimeFromSelects();
  //     } else if (["wnyrefs","pcaha"].indexOf(enterpriseGroup) > -1) {
  //       // owners can see these elements, everyone else may not.
  //       if (!($("body").data("permission-owner"))) {
  //         removeRatingsFromSelects();
  //       }
  //     } else {
  //      removeRatingsFromSelects();
  //      removeEndTimeFromSelects();
  //     }
  //   }
  // })();

});
