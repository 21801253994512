$(() => {
  var fixMinHeight = function(){
    if ($('#footer:visible').length > 0){
      var neg = $('#top-nav').outerHeight() + $('#footer').outerHeight();
      var window_height = $(window).height();
      var minHeight = window_height - neg;
      if (minHeight > 0){
        $('.main-content').css('min-height', minHeight);
      }
    }
  }
  $(window).on("resize", fixMinHeight);
  fixMinHeight();
});
