$(() => {
  $( "#sort-ages" )
    .sortable()
    .bind('sortupdate', function(e, ui) {
      var ids = [];
      $("#sort-ages div").each(function(index,value){
        // console.log("")
        ids.push($(this).attr("data-age-group-id"));
      });
      $.ajax("/age_groups/sort.js", {
        data: { age_group_ids: ids},
        type: 'POST'
      })
    });
});
