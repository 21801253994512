$(() => {
  console.log("menu.js executed")
  // given a regex matching the current URL path, activate the "selector" menu item
  // and open the collapse if needed
  // if the current path matches the current URL path
  function setActiveMenuItem(selector,pathRegex){
    if ($(".primary-sidebar ul li.active").length > 0) {
      //console.log("already active " + selector+ " for " + pathRegex);
     return null;
    }
    var regex = new RegExp(pathRegex);
    if (window.location.pathname.match(regex)){
      let item = $(".primary-sidebar").find(selector)
      item.addClass("active").find(".collapse").collapse("show")
      //console.log("match " + selector+ " for " + pathRegex);
      return true;
    } else {
      //console.log("no match " + selector+ " for " + pathRegex);
      return false;
    }
  }

  setActiveMenuItem("#home-menu-item", "^\/welcome");
  if ($("body[data-permission-assignor]").length > 0){
    setActiveMenuItem("#people-menu-item", "^\/users/\\d+/games");
    setActiveMenuItem("#people-menu-item", "^\/lists");
    setActiveMenuItem("#availability-menu-item", "\/users/" + $("body").attr("data-user-id")+ "/free_dates")
  } else {
    setActiveMenuItem("#games-menu-item", "^\/users/\\d+/games");
  }
  setActiveMenuItem("#games-menu-item", "^((\/manager)?\/games)");
  setActiveMenuItem("#games-menu-item", "\/onboarding\/");
  setActiveMenuItem("#game-reports-menu-item", "\/game_reports");
  setActiveMenuItem("#free-dates-menu-item", "\/free_dates");
  setActiveMenuItem("#videos-menu-item", "^\/video");
  setActiveMenuItem("#conflicts-menu-item", "conflicts");
  setActiveMenuItem("#confirmations-menu-item", "\/confirmations");
  setActiveMenuItem("#availability-menu-item", "\/availability");
  setActiveMenuItem("#people-menu-item", "^\/users/\\d+/free_dates");
  setActiveMenuItem("#people-menu-item", "^\/(users|assignors|contacts|roles)");
  setActiveMenuItem("#financials-menu-item", "^\/ledger");
  setActiveMenuItem("#searches-menu-item", "^\/searches");
  setActiveMenuItem("#messages-menu-item", "\/messages");
  setActiveMenuItem("#reports-menu-item", "\/report");

  setActiveMenuItem("#maintenance-menu-item", "\/schools");
  setActiveMenuItem("#maintenance-menu-item", "\/teams");
  setActiveMenuItem("#maintenance-menu-item", "\/genders");
  setActiveMenuItem("#maintenance-menu-item", "\/venues");
  setActiveMenuItem("#maintenance-menu-item", "\/patterns");
  setActiveMenuItem("#maintenance-menu-item", "\/pay_scales");
  setActiveMenuItem("#maintenance-menu-item", "\/game_types");
  setActiveMenuItem("#maintenance-menu-item", "\/leagues");
  setActiveMenuItem("#maintenance-menu-item", "\/user_groups");
  setActiveMenuItem("#maintenance-menu-item", "\/age_groups");
  setActiveMenuItem("#maintenance-menu-item", "\/game_expense_rules");

  setActiveMenuItem("#venue-maps-menu-item", "\/venue_maps");
  setActiveMenuItem("#registration-menu-item", "\/registration_(types|documents)");
  
  setActiveMenuItem("#esignatures-menu-item", "\/esign");
});
