$(() => {
  // goal conversion: new trial site
  if ($("#google-signup").length > 0) {
    console.log("goal: new signup");
    if (typeof ga == "undefined") return;
    ga('send', 'event', 'Signup', 'Signup');
  };
  // conversion/ecommerce tracking
  if ($("#google-purchase").length > 0) {
    var tier = $("meta[name='assignr:tier']").prop("content");
    var amount = ($("#google-purchase").data('amount'));
    var category = $("#google-purchase").data('category');
    var purchaseId = ($("#google-purchase").data('id'));
    console.log("goal: google purchase");
    console.log(tier, amount, category, purchaseId);
    if (typeof ga == "undefined") return;
    ga('require', 'ecommerce');
    ga('ecommerce:addTransaction', {
      'id': purchaseId,                     // Transaction ID. Required.
      'affiliation': 'assignr.com',   // Affiliation or store name.
      'revenue': amount,               // Grand Total.
      'shipping': '0',                  // Shipping.
      'tax': '0'                     // Tax.
    });
    ga('ecommerce:addItem', {
      'id': purchaseId,                     // Transaction ID. Required.
      'name': catgory,    // Product name. Required.
      'category': tier, // category or variation
      'price': amount,                 // Unit price.
      'quantity': '1'                   // Quantity.
    });
    ga('ecommerce:send');
  }

});
