$(() => {
  $('body').on('click','a.form-action-submit',function(e){
    e.preventDefault();
    var href = $(this).attr("href");
    var form = $(this).closest("form");
    console.log('form', form)
    console.log('href', href)
    form.attr("action",href);
    form.submit();
  });
})
