import Dinero from 'dinero.js'
$(() => {
  $('#ledger-multi-check').on('keyup', '.money-input',function(){
    console.log('keyup multicheck')
    var zero = Dinero({amount: 0});
    var total = Dinero({amount: 0});
    var collection = document.getElementsByClassName('money-input');
    var values = [];
    for (var i = 0; i < collection.length; i++) {
      total = total.add(Dinero({amount: ledger.cents(collection[i].value)}));
      console.log(total);
    }
    console.log(values);
    $('#ledger_provider_multi_check_amount').val(total.toRoundedUnit(2));
    $('#ledger_provider_multi_check_amount_placeholder').html(total.toFormat());
  });
  $('#ledger-multi-check .money-input:first').trigger('keyup');
});
