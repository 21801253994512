$(() => {
  var isNewbie = $("body").attr("data-newbie") === "yes";
  var baseDomain = window.location.host.replace(/^[a-z0-9-]+/,'');
  var cookieOptions = {expires: 90, domain: baseDomain};
  if ($("#sidebox-content h4").length > 0){
    // set initial cookie with empty array
    if (!$.cookie("sideboxes")) $.cookie("sideboxes",[], cookieOptions);
    var boxesViewed = $.cookie("sideboxes");
    var sideboxName = $("#sidebox-content").attr("data-sidebox");
    
    // if we've already dismissed this sidebox
    // OR user is not a newbie, hide it and show just the link.
    if ($.inArray(sideboxName, boxesViewed) > -1 || !isNewbie) {
      $("#sidebox-link").show();
      var title = $("#sidebox h4:first").text();
      $("#sidebox-title").text(title);  
      $("#sidebox").hide();
    } else {
      $("#sidebox").show();
    }
  }
  
  $("#sidebox-title").click(function(){
    // on click, slide the box down
    $("#sidebox-link").hide();
    $("#sidebox").slideDown();
  });
  
  $("#sidebox i").click(function(){
    // on click, slide the box back up, and record the name of the sidebox in the cookie array
    $("#sidebox").slideUp();
    $("#sidebox-link").show();
    if (!$.cookie("sideboxes")) $.cookie("sideboxes",[], cookieOptions);
    
    var title = $("#sidebox h4:first").text();
    var boxesViewed = $.cookie("sideboxes");
    var sideboxName = $("#sidebox-content").attr("data-sidebox");
    $("#sidebox-title").text(title);  
    if ($.inArray(sideboxName, boxesViewed) == -1) {
      boxesViewed.push(sideboxName); 
    }
    $.cookie("sideboxes", boxesViewed, cookieOptions);
  });
  
});