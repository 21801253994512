$(() => {
  $('form.w9-form').on("change",'#ledger_w9_business_personal',function(){
    console.log('change!');
    if ($(this).val() == 'P') {
      $('form.w9-form .w9-hide-personal').hide();
      if ($('#ledger_w9_tax_classification').val() != 'individual'){
        $('#ledger_w9_tax_classification').val('individual')
        .trigger('change');
      }
    } else {
      $('form.w9-form .w9-hide-personal').show();
    }
  })
  .on('change','#ledger_w9_tax_classification',function(){
    var classification = $(this).val();
    if (classification == 'individual' && $('#ledger_w9_business_personal').val() != 'P') {
      $('#ledger_w9_business_personal').val('P').trigger('change');
    }
    if ($('#ledger_w9_business_personal').val() == 'B'){
      if (['individual','sole_prop','llc_single_member'].indexOf(classification) >= 0){
        $('#w9-disregarded-entity').hide();
      } else {
        $('#w9-disregarded-entity').show();
      }
    } else {
      $('#ledger_w9_tax_classification').val('individual');
    }
  })
  .on('submit',function(){
    $(this).find('input[type="text"]:hidden').val('')
  });

  $('form.w9-form #ledger_w9_business_personal').trigger("change");
  $('form.w9-form #ledger_w9_tax_classification').trigger("change");
});
