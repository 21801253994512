// https://www.pluralsight.com/guides/ruby-on-rails-nested-attributes
$(() => {
  $('body').on('click','[data-form-prepend]',function(e){
    var obj = $($(this).attr("data-form-prepend"));
    var target = $($(this).attr("data-form-prepend-container"));
    var container;
    var nowValue = new Date().getTime();
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", nowValue);
      });
    });
    if (typeof $(this).attr("data-form-prepend-container") == 'undefined'){
      obj.insertBefore(this);
    } else {
      var container = $($(this).attr("data-form-prepend-container"))
      if (container[0]){
        obj.insertAfter(container[0]);
      } else {
        obj.insertBefore(this);
      }
    }
    return false;
  });
});
