$(() => {
  $('#new_inbox_message select[multiple]').select2({allowClear: true})
  $('#expand_to_global_ids').on("click",function(e){
    e.preventDefault()
    var recipients = $('#inbox_message_to_global_ids').val() || [];
    $.post('/messages/expand.json', {recipients: recipients})
    .done(function(data){
      $('#inbox_message_to_global_ids').val(data).trigger('change')
    })
  })
  $('#expand_bcc_global_ids').on("click",function(e){
    e.preventDefault()
    var recipients = $('#inbox_message_bcc_global_ids').val() || [];
    $.post('/messages/expand.json', {recipients: recipients})
    .done(function(data){
      $('#inbox_message_bcc_global_ids').val(data).trigger('change')
    })
  })
});
