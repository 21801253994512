$(() => {
  // var modal = $('#zone-selector-modal');
  // modal.find('select[multiple=multiple]').select2();
  // modal.find('.select-all').on('click',function(){
  //   modal.find("select[name='zone_scope[]'] option")
  //     .prop('selected', true)
  //     .trigger('change');
  // });
  // modal.find('.select-none').on('click',function(){
  //   modal.find("select[name='zone_scope[]'] option")
  //     .removeProp('selected')
  //     .trigger('change');
  // });
});
