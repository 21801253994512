import Cookies from 'js-cookie'
try {
  // get current timezone from browser & send to Assignr w/ cookie
  const metaTag = document.querySelector("meta[name=tld]")
  const tld = (metaTag && `.${metaTag.content}`) || '.assignr.com'
  const cookies = Cookies.withAttributes({ expires: 365, domain: tld })
  const timeZoneOffset = new Date().getTimezoneOffset().toString();  
  cookies.set('browser_tz', Intl.DateTimeFormat().resolvedOptions().timeZone)
  cookies.set('browser_tz_offset',timeZoneOffset)
} catch (error) {
  console.log("could not set time zone cookie",error)
}