import 'jquery'
import 'bootstrap/dist/js/bootstrap'
import 'select2/dist/js/select2.full.js'

import './jquery/jquery.ajaxmanager'
import './csrf.js'
import './footer-fix.js'
import './jquery/jquery.cookie'
import './jquery/jquery.cookie.config'
import './jquery/jquery.sortable'
import './jquery/jquery.ajax.progress'
import "./master/abilities.js"
import "./master/age_groups.js"
import "./master/analytics.js"
import "./master/dropcharge.js"
import "./master/developer.js"
import "./master/feeds.js"
import "./master/free_dates.js"
import "./master/games.js"
import "./master/has-many-fields.js"
import "./master/hide-sidebar.js"
import "./master/import_files.js"
import "./master/leagues.js"
import "./master/master-all.js"
import "./master/menu_indicator.js"
import "./master/menus.js"
import "./master/messages.js"
import "./master/password_reset.js"
import "./master/pay_scales.js"
import "./master/ready-hide.js"
import "./master/schools.js"
import "./master/searches.js"
import "./master/select2-customizations.js"
import "./master/self_assignables.js"
import "./master/sidebox.js"
import "./master/signup.js"
import "./master/sites.js"
import "./master/stripe.js"
import "./master/unsubscribe.js"
import "./master/users.js"
import "./master/venues.js"
import "./master/video.js"
import "./master/zones.js"
import "./registration/types.js"
import "./ledger/batch_payment/check.js"
import "./ledger/dwolla_customer.js"
import "./ledger/form-submit.js"
// this one just defines some common functions so no need to import
// import "./ledger/numbers.js"
import "./ledger/payments.js"
import "./ledger/payor.js"
import "./ledger/payor"
import "./ledger/quickbooks.js"
import "./ledger/statements.js"
import "./ledger/timesheets.js"
import "./ledger/w9.js"
import "./timezone.js"

// expose $ and jQuery
window.$ = $
window.jQuery = $
$(() => {

  $("select.select2-enabled").select2();

  // bootstrap uses "has-error" class, Rails uses "field_with_errors" class
  $(".field_with_errors").parent().addClass("has-error");
});
