$(() => {
  function formatCurrency(num) {
      num = isNaN(num) || num === '' || num === null ? 0.00 : num;
      return "$" + parseFloat(num).toFixed(2);
  }

  $('form.new_upgrade')
    .on('change', '#upgrade_new_plan_id', function(){
      $('span#upgrade-total-due').html(
        formatCurrency(
          $('#upgrade_new_plan_id').children('option:selected').attr('data-total')
        )
      );
    })
    .on("change","#choose_tier",function(){
      var tier = $(this).val();
      var count = $('form.new_upgrade #upgrade_new_plan_id option:selected').data("limit");
      console.log("change#choose_tier");
      console.log([tier,count]);
      $("#upgrade_new_plan_id").html($("#hidden_new_plan_id").html());
      $("#upgrade_new_plan_id option").addClass("remove")
      $("#upgrade_new_plan_id option[data-tier="+tier+"]").removeClass("remove");
      $("#upgrade_new_plan_id option.remove").remove();
      if (count) {
        console.log("choosing "+ count);
        $("#upgrade_new_plan_id option[data-limit="+count+"]").attr("selected",true);
      }
      $('form.new_upgrade #upgrade_new_plan_id').trigger('change');
    });
  $('form.new_upgrade #choose_tier').trigger('change');
  $('form.new_upgrade #upgrade_new_plan_id').trigger('change');
  if ($("#renewal_customer_payment_profile_id").index() == 1){
    if( $("#renewal_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").show();
    }else{
      $("#credit-card-group").hide();
    }
  }
  $("#renewal_customer_payment_profile_id").change(function(){
    if( $("#renewal_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").slideDown();
    }else{
      $("#credit-card-group").slideUp();
    }
  });

  if ($("#upgrade_customer_payment_profile_id").index() == 1){
    if( $("#upgrade_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").show();
    }else{
      $("#credit-card-group").hide();
    }
  }
  $("#upgrade_customer_payment_profile_id").change(function(){
    if( $("#upgrade_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").slideDown();
    }else{
      $("#credit-card-group").slideUp();
    }
  });

  if ($("#payment_customer_payment_profile_id").index() == 1){
    if( $("#payment_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").show();
    }else{
      $("#credit-card-group").hide();
    }
  }
  $("#payment_customer_payment_profile_id").change(function(){
    if( $("#payment_customer_payment_profile_id").val() == "" ){
      $("#credit-card-group").slideDown();
    }else{
      $("#credit-card-group").slideUp();
    }
  });

  $(".new_renewal").on("change","#choose_tier",function(){
    var count = $("#renewal_plan_id option:selected").data("limit");
    var tier = $(this).val();
    console.log([count,tier]);

    $("#renewal_plan_id").html($("#hidden_plan_id").html());
    $("#renewal_plan_id option").addClass("remove")
    $("#renewal_plan_id option[data-tier="+tier+"]").removeClass("remove");
    $("#renewal_plan_id option.remove").remove();
    if (count) {
      $("#renewal_plan_id option[data-limit="+count+"]").attr("selected",true);
    }
    $('form.new_renewal #renewal_plan_id').trigger('change');
  });
  $(".new_renewal #choose_tier").trigger("change");




});
