$(() => {
  function sideboxOverlapsTable(){
    // supress this behavior in the view if needed
    // set variable alwaysShowSidebox to true to keep the yellow sidebar
    if( typeof(alwaysShowSidebox) != "undefined" ){ return false; }
    var returnValue = false;
    $("div#yield").find("table").each(function(i){
      if(
        $("div#sidebox").position().left > 0
        && $("div#sidebox").position().left < ($(this).position().left + $(this).width())
      ){ returnValue = true; }
    });
    // window._firebug.debug(returnValue);
    return returnValue;
  }

  // on startup, hide the sidebox if it overlaps
  if (sideboxOverlapsTable() || $(window).width() < 650){
    $("div#sidebox").hide();
    $("div#mainbox").css('width','auto').css('margin-right','20px');
  }

  // close up the sidebox if the inner tables overlap the sidebar
  $(window).resize(function(){
    if ( sideboxOverlapsTable()){
      $("div#sidebox").animate({width:"0px", margin:"0px", padding:"0px", border: "0px none"},350).children().hide();
      $("div#mainbox").animate({width:"auto", marginRight:"20px"}, 350);
    };
  });

});
