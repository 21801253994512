$(() => {


  if($("#games-menu").length){
    $.getJSON("/menu_indicator.json").done(function(data){
       var assignor = data.counts.assignor;
       var referee = data.counts.referee;

       function appendBadge(selector, value, color){
         if (value === undefined) return false;
         color = color || "default";
         $("#games-menu").find(selector).removeClass("countable").find("a").append(
           '<span class="badge badge-'+color+' pull-right">'+value+'</span>');
       }

       if (assignor.accepted) appendBadge("li[data-count='accepted']",assignor.accepted, "green");
       if (assignor.declined) appendBadge("li[data-count='declined']",assignor.declined, "red");
       if (assignor.unconfirmed){
         appendBadge("li[data-count='unconfirmed']",assignor.unconfirmed, "red");
         $("#tasks-menu-item #task-unconfirmed span[data-task-completed]").attr("data-task-completed", "no")
       } else {
         $("#tasks-menu-item #task-unconfirmed span[data-task-completed]").attr("data-task-completed", "yes")
       }

       if (assignor.assigned) appendBadge("li[data-count='assigned']",assignor.assigned);
       if (assignor.unassigned) appendBadge("li[data-count='unassigned']",assignor.unassigned);
       if (assignor.published) appendBadge("li[data-count='published']",assignor.published);
       if (assignor.unpublished) appendBadge("li[data-count='unpublished']",assignor.unpublished, "blue");
       if (assignor.pending_requests) appendBadge("li[data-count='pending-requests']",assignor.pending_requests, "blue");
      //  if (assignor.conflict) appendBadge("li[data-count='conflict']",assignor.conflict, "red");
       if (assignor.total) appendBadge("li[data-count='all']",assignor.total);

       // if (referee.assigned)
       appendBadge("li[data-count='my']",referee.assigned, "blue");
       if (referee.pending) {
         games = (referee.pending == 1) ? "1 Game" : (referee.pending + " Games");
         $("#tasks-menu-item #task-unconfirmed span[data-item='game-count']").html(games);
       } else {
         $("#tasks-menu-item #task-unconfirmed").remove();
       }
       // if (referee.pending)
       if ( referee.pending && referee.pending > 0) {
         appendBadge("li[data-count='my-pending']",referee.pending, "red");
         $("#tasks-menu-item #task-unconfirmed").attr("data-task-completed", "no");
       } else if (referee.pending && referee.pending == 0 && referee.assigned && referee.assigned > 0) {
         appendBadge("li[data-count='my-pending']",referee.pending, "green");
         $("#tasks-menu-item #task-unconfirmed").attr("data-task-completed", "yes") ;
       } else {
         appendBadge("li[data-count='my-pending']",referee.pending);
         $("#tasks-menu-item #task-unconfirmed").attr("data-task-completed", "yes");
       }
       switch(referee.availability) {
       case "yes":
         $("#task-availability")
           .attr("data-task-completed", "yes") ;
         break;
       case "no":
         $("#free-dates-menu-item a")
           .append("<span title='You are not available to work.' class='badge badge-red pull-right'><b class='fa-solid fa-triangle-exclamation'></b></span>");
         $("#task-availability")
           .attr("data-task-completed", "no");
         break;
       default:
         $("#tasks-menu-item #task-availability").remove();
       }

       if (referee.has_address) {
         $("#task-address").attr("data-task-completed",referee.has_address);
       } else {
         $("#task-address").remove();
       }

       $("#task-avatar").attr("data-task-completed",referee.avatar);       
       $("#task-mobile").attr("data-task-completed",referee.mobile);
       // switch(referee.mobile){
       //   case "yes":
       //   case "no":
       //     $("#task-mobile").attr("data-task-completed",referee.mobile);
       //     break;
       //   case "confirm":
       //     $("#task-mobile").attr("data-task-completed","no");
       //     break;
       // }

       var incompleteTaskCount =  $("#tasks-menu-item li[data-task-completed!='yes']").length
       if (incompleteTaskCount > 0){
         $("#tasks-menu-item .badge").html(incompleteTaskCount).show();
       }

       $("#games-menu li.countable").remove();

    });
  }
});
